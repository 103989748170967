<template>
  <section id="gameTypes">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">{{ $t("common.menuList.gameTypes") }}</h3>
      </div>

      <div class="row">
        <div class="accordion">
          <div
            class="accordion-cell"
            v-for="(list, gameType) in gameList"
            :key="gameType"
            :class="{
              expanded: selectedGameTypes === gameType,
              collapsed: selectedGameTypes && selectedGameTypes !== gameType,
            }"
            @click="handleClickGameType(gameType)"
          >
            <img
              class="background-img"
              :src="require(`@/assets/images/gameType/${gameType}.png`)"
            />
            <h4
              v-if="
                devicePath !== 'pad' ||
                !selectedGameTypes ||
                selectedGameTypes === gameType
              "
            >
              {{ $t(`common.gameType.${gameType}`) }}
            </h4>
            <template
              v-if="devicePath === 'app' || selectedGameTypes === gameType"
            >
              <div class="accordion-cell-content">
                <p>
                  {{ $t(`common.gameType.description.${gameType}`) }} -
                  <span v-for="(game, index) in list" :key="game.gameCode">
                    {{ $t(`common.gameName.${game.gameCode}`) }}
                    <i v-if="index !== list.length - 1">, </i>
                  </span>
                  <span v-if="list.length === 3">{{ $t("common.etc") }}</span>
                </p>
                <ul>
                  <li
                    v-for="game in list"
                    :key="game.gameCode"
                    @click="
                      goPage({
                        page: 'Detail',
                        params: { gameName: game.gameName },
                      })
                    "
                  >
                    <img
                      :src="
                        require(`@/assets/images/gameIcon/${game.gameCode}.png`)
                      "
                    />
                    <span>{{ $t(`common.gameName.${game.gameCode}`) }}</span>
                  </li>
                  <li
                    class="more"
                    @click="goPage({ page: 'List', params: { gameType } })"
                  >
                    <i class="fas fa-arrow-circle-right"></i>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import {
  defineComponent, inject, onMounted, reactive, ref,
} from 'vue';
import { forEach, find, isEmpty } from 'lodash';
import games from '@/config/games.json';

export default defineComponent({
  setup() {
    const brandConfig = inject('brandConfig');
    const devicePath = inject('devicePath');
    const goPage = inject('goPage');
    const gameList = reactive({});
    const selectedGameTypes = ref(null);
    const handleClickGameType = (key) => {
      if (devicePath.value !== 'app') { selectedGameTypes.value = key; }
    };
    const getGameList = () => {
      forEach(brandConfig.gameList, (game) => {
        const match = find(games.gameList, (item) => item.gameCode === game.gameCode);
        if (match) {
          const gameLength = devicePath.value === 'pad' ? 2 : 3;
          if (gameList[match.gameTypes[0]] && gameList[match.gameTypes[0]].length < gameLength) {
            gameList[match.gameTypes[0]].push(game);
          } else if (!gameList[match.gameTypes[0]]) {
            gameList[match.gameTypes[0]] = [game];
          }
        }
      });
      if (!isEmpty(gameList)) {
        handleClickGameType(Object.keys(gameList)[0]);
      }
    };
    onMounted(() => {
      getGameList();
    });
    return {
      devicePath,
      gameList,
      selectedGameTypes,
      handleClickGameType,
      goPage,
    };
  },
});
</script>

<style lang="scss" scoped>
#gameTypes {
  overflow: hidden;
  background: url("../../assets/images/gametype.jpg") fixed center center;
  background-size: cover;
  padding: 100px 0;
  @media (max-width: 767px) {
    padding: 50px 0;
  }
  .section-header .section-title {
    color: #fff;
    text-shadow: 1px 2px 5px #000;
  }
  .accordion {
    position: relative;
    display: flex;
    width: 100%;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    .accordion-cell {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      width: 100%;
      transition-property: width;

      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0 10px;
      padding: 150px 0;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.7);
      box-shadow: 0 0 10px rgba(255, 255, 55, 0.5);
      img {
        width: 100px;
        margin-bottom: 20px;
      }
      h4 {
        display: block;
        margin: 0;
        color: #fff;
        font-size: 1.5em;
        transition-property: left right transform;
        transition-duration: 250ms;
      }
      .accordion-cell-content {
        display: block;
        width: 100%;
        max-height: 80vh;
        margin: 0;
        padding: 2rem 4rem 4rem;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        z-index: 5;
        p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }
        ul {
          margin: 20px 0 0;
          padding: 0;
          list-style: none;

          display: flex;
          align-items: stretch;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          li {
            @media (min-width: 768px) {
              width: 25%;
            }
            display: block;
            color: #fff;
            text-align: center;
            padding: 10px 20px;
            img {
              width: 70px;
              margin-bottom: 10px;
            }
            span {
              display: block;
            }
            i {
              font-size: 50px;
            }
          }
        }
      }
      .more {
        align-self: center;
      }
      &.expanded {
        display: block;
        padding: 0;
        width: 70%;
        background: rgba(0, 0, 0, 1);
        .background-img {
          position: absolute;
          width: 200px;
          opacity: 0.2;
          right: 20px;
          bottom: 0;
        }
        h4 {
          font-size: 2em;
          padding: 6rem 4rem 0;
          @media (max-width: 1199px) {
            padding: 4rem 2rem 0;
          }
        }
        .accordion-cell-content {
          transition-duration: 250ms;
        }
      }
      &.collapsed {
        width: 10%;
        img {
          width: 60%;
        }
        h4 {
          font-size: 1em;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  #gameTypes {
    .accordion {
      margin: 0 -5px;
      .accordion-cell {
        width: 25%;
        margin: 0 5px;
        &.expanded {
          width: 70%;
        }
        &.collapsed {
          width: 10%;
        }
        .accordion-cell-content {
          // opacity: 1;
          padding: 2rem;
          li {
            padding: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #gameTypes {
    .accordion {
      margin: 0;
      .accordion-cell {
        width: 100%;
        height: auto;
        margin: 1rem 0;
        border-radius: 10px;
        padding: 50px 0 0;
        &.expanded,
        &.collapsed {
          width: 100%;
          display: flex;
          padding: 50px 0 0;
          background: rgba(0, 0, 0, 0.7);
        }
        .accordion-cell-content {
          padding: 2rem 2rem 4rem;
          display: block;
          padding: 2rem;
          max-height: fit-content;
        }
        img {
          width: 60px;
          position: relative;
          right: unset;
          bottom: unset;
        }
        h4 {
          font-size: 1.5em;
          padding: 0;
        }
        li {
          width: 32%;
        }
      }
    }
  }
}
</style>
