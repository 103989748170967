<template>
  <section
    id="banner"
    :class="{ landscape: devicePath !== 'web' && orientation === 'landscape' }"
  >
    <div class="container banner-container">
      <div class="banner-text" data-aos="zoom-out-down">
        <h2>{{ $t("common.welcome") }} {{ brandConfig.displayName }}</h2>
        <p>{{ $t("common.slogan") }}</p>
      </div>
    </div>
    <div class="shape-box shape-bottom" data-negative="true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          class="shape-fill"
          d="M0,0 L1000,0 L1000,100 L200,0 L0,50z"
        ></path>
      </svg>
    </div>
    <button class="btn-scroll" @click="handleLinkClick('about')">
      {{ upperCase($t("common.scroll")) }}<br />
      <i class="fas fa-chevron-down"></i>
    </button>
  </section>
</template>

<script>
import { defineComponent, inject } from 'vue';
import { upperCase } from 'lodash';

export default defineComponent({
  setup() {
    const brandConfig = inject('brandConfig');
    const devicePath = inject('devicePath');
    const orientation = inject('orientation');
    const handleLinkClick = inject('handleLinkClick');
    return {
      upperCase,
      brandConfig,
      devicePath,
      orientation,
      handleLinkClick,
    };
  },
});
</script>

<style lang="scss" scoped>
#banner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/banner.jpg") center top no-repeat;
  background-size: cover;
  position: relative;
  &.landscape {
    @media (max-width: 991px) {
      height: 100vh;
      align-items: flex-end;
      .banner-container {
        margin-bottom: 10px;
      }
      svg,
      .btn-scroll {
        display: none;
      }
      .banner-text {
        width: 50%;
      }
      .product-screens {
        width: 50%;
      }
    }
    @media (max-width: 991px) and (min-width: 768px) {
      h2 {
        font-size: 32px;
        line-height: 36px;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }
  }
  .banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .shape-box.shape-bottom svg {
    height: 200px;
    transform: rotateY(180deg) rotateZ(180deg);
  }
  .btn-scroll {
    font-family: "Montserrat", sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
    width: 150px;
    padding: 0 10px;
    transition: 0.5s;

    position: absolute;
    z-index: 1;
    &:hover {
      color: #cc00ff;
    }
  }
  .banner-text {
    text-shadow: 1px 2px 5px #000;
    text-align: left;
  }
  .product-screens {
    max-width: 560px;
    width: 100%;

    box-shadow: 0 0 10px #fffdca99;
    border-radius: 5px;
    overflow: hidden;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    margin: 0;
    padding: 10px 20px;
    font-size: 60px;
    font-weight: 600;
    line-height: 56px;
    color: #fff;
  }
  p {
    color: #fff;
    margin-bottom: 0;
    padding: 10px 20px;
    font-size: 32px;
  }
}
@media (max-width: 480px) {
  #banner {
    padding-bottom: 100px;
    &::after {
      content: "";
      display: block;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      position: absolute;
    }
    .shape-bottom {
      bottom: 60px;
    }
    .shape-box.shape-bottom svg {
      height: 60px;
    }
  }
}
@media (min-width: 1025px) {
  #banner {
    background-attachment: fixed;
  }
}
@media (max-width: 1199px) {
  #banner {
    .banner-text,
    .product-screens {
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
  }
}
@media (max-width: 767px) {
  #banner {
    height: 82vh;
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    p {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
}
</style>
