<template>
  <banner />
  <about />
  <game-type />
  <our-service />
  <partnership />
</template>

<script>
import {
  defineComponent, inject, onMounted, nextTick,
} from 'vue';
import { useRoute } from 'vue-router';
import Banner from '@/components/Home/Banner.vue';
import About from '@/components/Home/About.vue';
import GameType from '@/components/Home/GameType.vue';
import OurService from '@/components/Home/OurService.vue';
import Partnership from '@/components/Home/Partnership.vue';

export default defineComponent({
  setup() {
    const $route = useRoute();
    const handleLinkClick = inject('handleLinkClick');
    onMounted(() => {
      window.scrollTo(0, 0);
      if ($route.params.link) {
        nextTick(() => {
          handleLinkClick($route.params.link);
        });
      }
    });
    return {
      handleLinkClick,
    };
  },
  components: {
    Banner,
    About,
    GameType,
    OurService,
    Partnership,
  },
});
</script>
