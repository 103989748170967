<template>
  <section id="partnership">
    <div class="shape-box shape-top" data-negative="true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          class="shape-fill"
          d="M0,0 L1000,0 L1000,100 L200,0 L0,50z"
        ></path>
      </svg>
    </div>

    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">{{ $t("common.menuList.partnership") }}</h3>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
#partnership {
  overflow: hidden;
  background: url("../../assets/images/partnership.jpg") top 100px center
    no-repeat;
  background-size: cover;
  margin-top: 100px;
  padding: 0 0 40%;
  .shape-top {
    top: 100px;
  }
  .shape-box.shape-top svg {
    height: 200px;
    transform: rotateY(180deg);
    @media (max-width: 480px) {
      height: 100px;
    }
  }
  @media (max-width: 767px) {
    padding: 50px 0 50%;
  }
  @media (max-width: 480px) {
    padding: 50px 0 70%;
  }
}
</style>
