<template>
  <section id="ourService">
    <div class="shape-box shape-bottom">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path class="shape-fill" d="M0,0 L1000,0 L1000,40 L0,100z"></path>
      </svg>
    </div>

    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">{{ $t("common.menuList.ourService") }}</h3>
      </div>

      <div class="row ourServiceList">
        <div class="box" v-for="(item, type) in serviceList" :key="type">
          <img :src="require(`@/assets/images/service-${type}.jpg`)" />
          <h4 class="title">{{ $t(`common.ourService.title.${type}`) }}</h4>
          <div class="detail">
            <h4>{{ $t(`common.ourService.title.${type}`) }}</h4>
            <ul v-if="item > 1">
              <li v-for="n in item" :key="n">
                {{ $t(`common.ourService.description.${type}-${n}`) }}
              </li>
            </ul>
            <p v-else>
              {{ $t(`common.ourService.description.${type}-1`) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const serviceList = {
      api: 1,
      admin: 3,
      risk: 3,
    };
    return {
      serviceList,
    };
  },
});
</script>

<style lang="scss" scoped>
#ourService {
  overflow: hidden;
  background: url(../../assets/images/bg01.jpg) fixed center center;
  background-size: cover;
  padding: 100px 0 200px;
  @media (max-width: 767px) {
    padding: 50px 0 100px;
  }
  .shape-box.shape-bottom svg {
    height: 400px;
    transform: rotateZ(180deg);
  }
  .section-header .section-title {
    color: #fff;
    text-shadow: 1px 2px 5px #000;
  }
  .ourServiceList {
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
  .box {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    transition: 0.4s;
    position: relative;
    overflow: hidden;
    margin: 0 1%;
    @media (max-width: 767px) {
      margin-bottom: 8%;
    }
    .title {
      margin: 0;
      padding: 50px 10px;
      text-align: center;
      font-size: 1.5rem;
      @media (max-width: 1299px) {
        display: none;
      }
    }
    .detail {
      box-sizing: border-box;
      position: absolute;
      padding: 40px;
      bottom: 0;
      left: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 1s ease;
      transform: translateY(30px);
      z-index: 5;
      display: block;
      width: 100%;
      background: #fff;
      h4 {
        font-size: 1.5rem;
      }
      li {
        line-height: 1.5;
      }
      @media (max-width: 1299px) {
        position: relative;
        padding: 20px;
        opacity: 1;
        transform: translateY(0);
      }
    }
    &:hover .detail {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
