<template>
  <section id="about">
    <div class="shape-box shape-top">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path class="shape-fill" d="M0,0 L1000,0 L1000,40 L0,100z"></path>
      </svg>
    </div>
    <div data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">{{ $t("common.menuList.about") }}</h3>
      </div>
      <div class="container">
        <div class="row">
          <div class="about-first-container">
            <div class="box-title">
              <h1 class="title">{{ brandConfig.gameList.length }}</h1>
              <p class="description">
                {{ $t("common.about.description.gameTypes") }}
              </p>
            </div>
            <ul class="box-content">
              <li v-for="item in brandConfig.gameList" :key="item.gameCode">
                <img
                  :src="
                    require(`@/assets/images/gameIcon/${item.gameCode}.png`)
                  "
                />
              </li>
            </ul>
          </div>
          <div class="about-second-container">
            <div class="service-currency-container">
              <div class="box service">
                <div class="box-title">
                  <h1 class="title">{{ $t("common.about.title.service") }}</h1>
                </div>
                <p class="description">
                  {{ $t("common.about.description.service") }}
                </p>
              </div>
              <div class="box currency">
                <div class="box-title">
                  <h1 class="title">{{ brandConfig.currencyList.length }}</h1>
                  <p class="description">
                    {{ $t("common.about.description.currency") }}
                  </p>
                </div>
                <ul class="box-content">
                  <li
                    v-for="item in brandConfig.currencyList"
                    :key="item"
                    class="currency"
                  >
                    <img
                      :src="require(`@/assets/images/currency/${item}.png`)"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="device-language-container">
              <div class="box device">
                <div class="box-title">
                  <h1 class="title">5</h1>
                  <p class="description">
                    {{ $t("common.about.description.device") }}
                  </p>
                </div>
                <img :src="require(`@/assets/images/devices.png`)" alt="" />
              </div>
              <div class="box language">
                <div class="box-title">
                  <h1 class="title">
                    {{ brandConfig.gameLanguageList.length }}
                  </h1>
                  <p class="description">
                    {{ $t("common.about.description.language") }}
                  </p>
                </div>
                <ul class="box-content">
                  <li
                    v-for="item in brandConfig.gameLanguageList"
                    :key="item"
                    class="language"
                  >
                    <img
                      :src="require(`@/assets/images/language/${item}.jpg`)"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, inject } from 'vue';

export default defineComponent({
  setup() {
    const brandConfig = inject('brandConfig');
    return {
      brandConfig,
    };
  },
});
</script>

<style lang="scss" scoped>
#about {
  overflow: hidden;
  background: url("../../assets/images/bg01.jpg") fixed center center;
  background-size: cover;
  padding: 100px 12px;
  .shape-box.shape-top svg {
    height: 600px;
  }
  .about-first-container {
    margin: 1%;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    transition: 0.4s;
    position: relative;
    overflow: hidden;
    @media (min-width: 1400px) {
      width: 29%;
    }
    @media (min-width: 992px) and (max-width: 1399px) {
      width: 27%;
    }
    @media (max-width: 991px) {
      padding: 30px;
    }
    ul li img {
      width: 60%;
    }
  }
  .about-second-container {
    width: 100%;
    @media (min-width: 992px) {
      width: 62%;
      display: flex;
      flex-wrap: wrap;
    }
    .service-currency-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box {
        width: 100%;
        @media (min-width: 768px) {
          width: 35%;
        }
        @media (min-width: 992px) {
          width: 33%;
        }
        @media (min-width: 1200px) {
          width: 35%;
        }
        @media (min-width: 1400px) {
          width: 36%;
        }
        margin: 1.5%;
      }
    }
    .device-language-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box {
        width: 100%;
        @media (min-width: 768px) {
          width: 35%;
        }
        @media (min-width: 992px) {
          width: 33%;
        }
        @media (min-width: 1200px) {
          width: 35%;
        }
        @media (min-width: 1400px) {
          width: 36%;
        }
        margin: 1.5%;
      }
    }
  }
  .box {
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    transition: 0.4s;
    position: relative;
    overflow: hidden;
    &.service {
      background: url("../../assets/images/service.png") right 20px bottom 0
        no-repeat #fff;
      background-size: 55%;
      @media (max-width: 767px) {
        min-height: 40%;
      }
      .description {
        width: 50%;
      }
    }
  }

  .box-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .box-content {
    display: flex;
    flex-wrap: wrap;
    .currency,
    .language {
      width: 21%;
      padding: 2%;
    }
  }
  .title {
    color: #000;
    font-weight: bold;
    position: relative;
    padding-right: 25px;
    margin-right: 20px;
    margin-bottom: 0;
    &::before {
      content: "";
      display: block;
      width: 5px;
      height: 60%;
      background: linear-gradient(to bottom, #cc00ff, #580f5f);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .description {
    margin-bottom: 0;
    color: #888;
    font-size: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      width: 33%;
      padding: 10px 0;
      text-align: center;
      @media (min-width: 768px) and (max-width: 991px) {
        width: 20%;
      }
    }
  }
}
@media (max-width: 767px) {
  #about {
    padding: 50px 12px;
    .box.service {
      background-position: right 10px top 0;
    }
  }
}
@media (max-width: 991px) {
  #about .box {
    &.gametype ul li {
      width: 20%;
      img {
        width: 80%;
      }
    }
    &.service {
      background-position: right 10px bottom 0;
      background-size: 50%;
    }
  }
}
@media (max-width: 480px) {
  #about .box {
    padding: 30px;
    &.gametype ul li {
      width: 25%;
      img {
        width: 100%;
      }
    }
    &.service {
      background-position: right 10px bottom 0;
      background-size: 40%;
    }
  }
}
</style>
